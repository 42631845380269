<script setup >
import {LANG_EN, LANG_ZH_CN, LANG_ZH_TC} from '~/consts/const';
import {LangStore} from "~/common-store/lang-store";
import MenuSelect from "../menu-select/MenuSelect";
import MenuSelectItem from "../menu-select/MenuSelectItem";
import {LanguageIcon} from "@heroicons/vue/24/outline";

const lang = LangStore();

const langChange = (langID) => {
  lang.defaultLangUpdate(langID);
}

</script>

<template>
  <MenuSelect class="w-30">
    <template #label>
      <LanguageIcon class="w-5"/>
    </template>
    <MenuSelectItem @click="langChange(LANG_EN)" class="w-30">
      <div class="w-20">English</div>
    </MenuSelectItem>
    <MenuSelectItem @click="langChange(LANG_ZH_CN)" class="w-30">
      <div>简体中文</div>
    </MenuSelectItem>
    <MenuSelectItem @click="langChange(LANG_ZH_TC)" class="w-30">
      <div>繁体中文</div>
    </MenuSelectItem>
  </MenuSelect>
</template>
